'use client';

import classNames from 'classnames';
import Image from 'next/image';
import { Button } from '@get-mosh/msh-shared';
import styles from './footer-banner.module.scss';
import HTMLReactParser from 'html-react-parser';
import { trackLandingPageButtonClick } from 'src/analytics/Events';
import { EventProps } from 'src/analytics/EventProps';

export type FooterBannerProps = {
    header: string;
    cta: {
        label: string;
        link: string;
        eventProps?: EventProps;
        hideOnMobile?: boolean;
    };
    image: {
        src: string;
        alt: string;
    };
    imageMobile?: {
        //if mobile image is different than the desktop one, otherwise the 'image' will be used
        src: string;
        alt: string;
    };
    className?: string;
};

const FooterBanner = ({
    className,
    header,
    cta,
    image,
    imageMobile,
}: FooterBannerProps) => {
    const onClick = () => {
        trackLandingPageButtonClick({
            location: 'Footer',
            label: cta.label,
            ...cta.eventProps,
        });
    };

    return (
        <div className={classNames(styles['wrapper'], className)}>
            <div className={styles['heading-container']}>
                <h2>{HTMLReactParser(header || '')}</h2>
                <Button
                    text={cta.label}
                    as="a"
                    href={cta.link}
                    onClick={onClick}
                    className={classNames(
                        cta.hideOnMobile ? styles['call-to-action'] : '',
                    )}
                />
            </div>

            {/* Desktop */}
            <div className={styles['image-container__desktop']}>
                <Image
                    src={image.src}
                    alt={image.alt}
                    width={725}
                    height={295}
                />
            </div>
            {/* Mobile */}
            <div className={styles['image-container__mobile']}>
                <Image
                    src={imageMobile ? imageMobile.src : image.src}
                    alt={imageMobile ? imageMobile.alt : image.alt}
                    width={725}
                    height={295}
                />
            </div>
        </div>
    );
};

export default FooterBanner;
