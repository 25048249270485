import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';

export type ListProps = {
    items: ListItemType[];
    className?: string;
    type?: 'ul' | 'ol' | undefined;
    html?: boolean | false;
    icon?: ListItemIconType;
};

export type ListItemIconType = {
    background?: string;
    border?: string | boolean;
    colour?: string;
    faIcon: IconProp;
    size?: string;
};
export type ListItemType = {
    text: string;
    className?: string;
};

const List = (props: ListProps) => {
    return (
        <div className={'mosh-list'}>
            {props.type === 'ol' ? (
                <OrderedList
                    items={props.items}
                    html={props.html}
                    icon={props.icon}
                    className={props.className}
                />
            ) : (
                <UnorderedList
                    items={props.items}
                    html={props.html}
                    icon={props.icon}
                    className={props.className}
                />
            )}
        </div>
    );
};

const OrderedList = ({ ...list }: ListProps) => {
    return (
        <ol className={list.className}>
            <RenderListItems
                items={list.items}
                html={list.html}
                icon={list.icon}
            />
        </ol>
    );
};

const UnorderedList = ({ ...list }: ListProps) => {
    return (
        <ul className={list.className}>
            <RenderListItems
                items={list.items}
                html={list.html}
                icon={list.icon}
            />
        </ul>
    );
};

const ListItem = (props: {
    html?: boolean;
    item: ListItemType;
    icon?: ListItemIconType;
}) => {
    return (
        <li
            className={classNames(
                props.icon &&
                    'items-top mb-2 flex flex-row items-center text-xs md:text-base',
                props.item.className,
            )}
        >
            {props.icon && (
                <div className="flex">
                    <FontAwesomeIcon
                        icon={props.icon.faIcon}
                        className={classNames(
                            props.icon.background,
                            '-mt-1 h-4 w-4 rounded-full pr-1',
                            props.icon.border === undefined
                                ? 'border border-green-dark'
                                : props.icon.border,
                            props.icon.colour || 'text-green-dark',
                            props.icon.size,
                        )}
                    />
                </div>
            )}
            <span>
                {props.html
                    ? HTMLReactParser(props.item.text)
                    : props.item.text}
            </span>
        </li>
    );
};

const RenderListItems = ({ ...list }: ListProps) => {
    return (
        <>
            {list.items.map((e, i) => (
                <ListItem key={i} item={e} html={list.html} icon={list.icon} />
            ))}
        </>
    );
};

export default List;
