'use client';

import { Button } from '@get-mosh/msh-shared';
import classNames from 'classnames';
import HTMLReactParser from 'html-react-parser';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from 'src/hooks/useIntersectionObserver';
import { Image as ImageProps } from 'src/types/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import List from '../List/List';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { trackLandingPageButtonClick } from 'src/analytics/Events';
import { EventProps } from 'src/analytics/EventProps';

import styles from './hero.module.scss';

export type HeroProps = {
    image: ImageProps;
    title: string;
    verticals: {
        image: ImageProps;
        title: string;
        href: string;
        eventProps: EventProps;
    }[];
    list: {
        text: string;
        className: string;
    }[];
    cta: {
        label: string;
        link: string;
        eventProps: EventProps;
    };
    className?: string;
};

const Hero = ({ cta, ...props }: HeroProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const intersectedButtonRef = useRef<HTMLDivElement | null>(null);

    const onScreen = useIntersectionObserver({
        ref,
        options: {
            threshold: 1,
            rootMargin: '0px 0px 50% 0px',
        },
    });

    const setButtonHeight = () => {
        const buttonHeight = intersectedButtonRef.current?.clientHeight;
        buttonHeight &&
            document
                .querySelector('html')
                ?.style.setProperty(
                    '--fixed-button-height',
                    buttonHeight + 'px',
                );
    };

    const onClick = (location: 'StickyMobile' | 'Header') => {
        trackLandingPageButtonClick({
            location: location,
            label: cta.label,
            ...cta.eventProps,
        });
    };

    const onVerticalClick = (label: string, eventProps: EventProps) => {
        trackLandingPageButtonClick({
            location: 'Header',
            label,
            ...eventProps,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', () => {
            setButtonHeight();
        });
    }, []);

    useEffect(() => {
        setButtonHeight();
    }, [onScreen]);

    return (
        <>
            <div className={styles['wrapper']} data-hero>
                <div className="flex-1">
                    <div
                        className={classNames(
                            styles['content'],
                            props.className,
                        )}
                    >
                        <div className="gap-4 sm:gap-2">
                            <h1 className="mb-4">
                                {HTMLReactParser(props.title)}
                            </h1>
                            <List
                                type={'ul'}
                                icon={{
                                    faIcon: faCircleCheck,
                                    border: false,
                                }}
                                html={true}
                                items={props.list}
                                className={classNames(
                                    'h4 highlight',
                                    styles['list'],
                                )}
                            />
                        </div>
                        <div
                            className={classNames(
                                'flex gap-4 md:flex-wrap',
                                styles['tiles'],
                            )}
                        >
                            {props.verticals.map((item) => (
                                <Link
                                    key={item.href}
                                    href={item.href}
                                    className={styles['tile']}
                                    onClick={() => {
                                        onVerticalClick(
                                            item.title,
                                            item.eventProps,
                                        );
                                    }}
                                >
                                    <Image
                                        className={styles['tile-image']}
                                        src={item.image.src}
                                        width={item.image.width}
                                        height={item.image.height}
                                        alt={props.title}
                                        sizes={'(max-width: 576px) 50vw, 256px'}
                                    />
                                    <div className={styles['tile-content']}>
                                        <h4 className="text-black">
                                            {item.title}
                                        </h4>
                                        <div className="flex gap-2 rounded-full bg-grey-100 p-4 text-grey-400">
                                            <FontAwesomeIcon
                                                icon={faArrowRightLong}
                                                width={'24px'}
                                                height={'24px'}
                                            />
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={classNames('flex-1', styles['hero-image'])}>
                    <Image
                        src={props.image.src}
                        width={props.image.width}
                        height={props.image.height}
                        alt={props.title}
                        priority
                    />
                </div>
            </div>
            {/* CTA */}
            <div
                ref={ref}
                className={classNames(
                    styles['cta'],
                    styles['headroom-btm-wrapper'],
                )}
            >
                {onScreen ? (
                    <>
                        {cta && (
                            <div
                                className={classNames(
                                    styles['btn_wrapper--default'],
                                )}
                            >
                                <Button
                                    as={Link}
                                    text={cta.label}
                                    href={cta.link}
                                    onClick={() => onClick('Header')}
                                    className="mb-0 w-full md:w-auto"
                                />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {cta && (
                            <div
                                ref={intersectedButtonRef}
                                className={classNames(
                                    styles['btn_wrapper--intersected'],
                                )}
                            >
                                <Button
                                    as={Link}
                                    text={cta.label}
                                    href={cta.link}
                                    onClick={() => onClick('StickyMobile')}
                                    className="mb-0 w-full md:w-auto"
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default Hero;
